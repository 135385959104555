/* eslint-disable */
import React, { createRef, Component } from "react";
import styled from "@emotion/styled";

import Renderer3D from "renderer3d-model/dist";
import config from "renderer3d-model/dist/config";

import Loading from "renderer3d-model/dist/loading";
import InfoPanel from "renderer3d-model/dist/infoPanel";
import Controls from "renderer3d-model/dist/controls";

const API_BASE =
  process.env.REACT_APP_API_URL || "https://api.medicinehub.cloud";

config.attachment.visibility = 3;
const getRequestUrl = () => {
  const id = window.location.pathname.slice(1);
  const currentSearch = new URLSearchParams(
    window.location.search.replace("?", "")
  );
  const params = [];
  currentSearch.forEach((val, key) => params.push({ key, val }));
  const { base, search } = params.reduce(
    (all, { key, val }) => {
      if (key === "base") return { ...all, base: val };
      if (all.search === "?") return { ...all, search: `?${key}=${val}` };
      return { ...all, search: `${all.search}&${key}=${val}` };
    },
    { base: null, search: "?" }
  );
  return `${base ? base : API_BASE}/anatomic_maps/${id}${search}`;
};
const getUrlParams = () => {
  const id = window.location.pathname.slice(1);
  const currentSearch = new URLSearchParams(
    window.location.search.replace("?", "")
  );
  const params = {};
  currentSearch.forEach((val, key) => {
    params[key] = val;
  });
  return params;
};
class Renderer3d extends Component {
  state = {
    auth: false,
    loading: false,
    show: true,
  };
  render3d = createRef();
  loading = createRef();
  infoPanel = createRef();
  configGui = createRef();
  controls = createRef();
  renderer = {};

  async componentDidMount() {
    const { viewOption: show = "4" } = getUrlParams();
    console.log({ show, next: show !== "4" });
    if (show !== "4") {
      this.setState({ show: false });
      config.attachment.visibility = Number(show) % 4;
    }
    const isAuth = await this.verifyAuth();
    this.setState({ loading: false });
    if (!isAuth) {
      return;
    }
    this.setState({ auth: true });
    await this.loadModel();
  }

  async verifyAuth() {
    try {
      const params = getUrlParams();
      const headers = params.token
        ? { authorization: `Bearer ${params.token}` }
        : {};
      const response = await fetch(getRequestUrl(), {
        method: "head",
        credentials: "include",
        headers,
      });
      return response;
    } catch (e) {
      console.log({ e });
      return false;
    }
  }

  loadModel = async () => {
    const params = getUrlParams();
    const headers = params.token
      ? { authorization: `Bearer ${params.token}` }
      : {};
    const response = await fetch(getRequestUrl(), {
      credentials: "include",
      headers,
    });
    const {
      url: modelUrl,
      m3d: { attachments, orbit },
    } = await response.json();
    this.renderer = new Renderer3D({
      modelUrl,
      loading: this.loading.current,
      infoPanel: this.infoPanel.current,
      container: this.render3d.current,
      configGui: this.configGui.current,
      editable: false,
      initial: {
        orbit,
        attachments,
      },
      callbacks: {
        addAttachment: console.log,
        removeAttachment: console.log,
        updateAttachmentData: console.log,
        updateAttachmentDefaultScreen: console.log,
        updateAttachmentPosition: console.log,
        updateDefaultOrbit: console.log,
        getCurrentState: console.log,
      },
    });
    this.renderer.animate();
  };

  render() {
    const { auth, loading } = this.state;
    if (loading) return null;
    if (!auth) return <h1>Not authenticated</h1>;
    return (
      <Container ref={this.render3d}>
        <Loading ref={this.loading} />
        <InfoPanel ref={this.infoPanel} />
        <Controls
          ref={this.controls}
          showFullscreen={false}
          hidePointView={!this.state.show}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export default Renderer3d;
